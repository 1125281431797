import React from 'react'
import _ from 'lodash'
import './premium-styles.scss'
import { getUrlMedia } from '../../../../helpers'
import { TableProduct, Button } from '../../../../components'

const Premium = ({
  t, id, data, brochure,
}) => {
  if (_.isEmpty(data)) {
    return null
  }
  return (
    <div className="container-xxl p-0" id={id}>
      <div className="premium__container">
        <div>
          <h2 className="page-subtitle">{t('product.premium.title')}</h2>
        </div>
        <div className="table-responsive">
          {data && <TableProduct data={data} />}
        </div>
        <div className="benefitstable__button">
          {brochure && (
            <a
              href={getUrlMedia(brochure)}
              target="_blank"
              download
              rel="noreferrer"
            >
              <Button text="View Full Brochure" />
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

export default Premium
