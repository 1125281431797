import React from 'react'
import './header-styles.scss'
import _ from 'lodash'

import { Button } from '../../../../components'
import { getUrlMedia } from '../../../../helpers'

const Header = ({
  t,
  name,
  brief,
  highlight,
  buyNowLink,
  promotion,
  category,
  subcategory,
  imgBg,
}) => (
  <div className="container-xxl p-0">
    <div
      style={{
        backgroundImage: `url(${getUrlMedia(imgBg)})`,
      }}
      className="headerProduct__container"
    >
      <div className="headerProduct__content">
        <p className="headerProduct__categories">
          {promotion === 1 && <span>promotion / </span>}
          <span>{subcategory || category}</span>
        </p>
        <h1 className="page-title">{name}</h1>
        <p className="headerProduct__description">{brief}</p>
        <div className="headerProduct__separator"></div>
        <ul className="headerProduct__list">
          {_.map(highlight, (item) => (
            <li key={item.highlight_text}>{item.highlight_text}</li>
          ))}
        </ul>
        <div>
          {buyNowLink ? (
            <div className="headerProduct__buttons">
              <Button link={buyNowLink} text={t('product.header.buttonBuy')} />
              <Button
                outline
                link="/quotation/"
                text={t('product.header.buttonText')}
              />
            </div>
          ) : (
            <Button link="/quotation/" text={t('product.header.buttonText')} />
          )}
        </div>
      </div>
    </div>
  </div>
)

export default Header
