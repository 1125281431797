import _ from 'lodash'
import React from 'react'
import { TableProduct, Button } from '../../../../components'
import { getUrlMedia } from '../../../../helpers'
import './benefits-table-styles.scss'

const BenefitsTable = ({ t, data, brochure }) => {
  if (_.isEmpty(data)) {
    return null
  }
  return (
    <div className="container-xxl p-0">
      <div className="benefitstable__container">
        <div>
          <h3 className="page-subtitle">{t('product.benefitsTable.title')}</h3>
        </div>
        <div className="table-responsive">
          {data && <TableProduct data={data} />}
        </div>
        <div className="benefitstable__button">
          {brochure && (
            <a
              href={getUrlMedia(brochure)}
              target="_blank"
              download
              rel="noreferrer"
            >
              <Button text="View Full Brochure" />
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

export default BenefitsTable
