import _ from 'lodash'
import React from 'react'
import './notes-styles.scss'

const Notes = ({ t, id, data }) => {
  if (_.isEmpty(data)) {
    return null
  }
  return (
    <div className="notes__container" id={id}>
      <div className="container-xxl p-0">
        <div>
          <h3 className="notes__title">{t('product.notes.title')}</h3>
          <p className="notes__description">{t('product.notes.description')}</p>
        </div>
        <div className="notes__list row">
          {_.map(data, (item, idx) => (
            <div key={idx} className="col-12 notes__item">
              <div className="notes__number">
                <span>{idx + 1}</span>
              </div>
              <div
                className="rich-text"
                dangerouslySetInnerHTML={{
                  __html: item.text,
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Notes
