import _ from 'lodash'
import React, { useState, useEffect, useRef } from 'react'
import classNames from 'classnames'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { Button } from '../../../../components'
import './menu-styles.scss'

const Menu = ({
  menu, t, slug, buyNowLink,
}) => {
  const [offsetTopElem, setOffsetTopElem] = useState(0)
  const [scrolled, setScrolled] = useState(false)
  const [offset, setOffset] = useState(0)
  const navRef = useRef(null)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.onscroll = () => {
        setOffset(window.pageYOffset)
      }
    }
  }, [])

  useEffect(() => {
    setOffsetTopElem(navRef.current.offsetTop)
  }, [])

  const handleScroll = () => {
    if (offset > offsetTopElem) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll)
      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }
  })

  const menuDefaultText = [
    'Summary',
    'Benefits',
    'Premium Overview',
    'Downloads',
    'Notes & Exclusions',
  ]

  const containerNavbar = classNames({
    menuProduct__container: true,
    menuProduct__sticky: scrolled,
  })

  return (
    <div className={containerNavbar} ref={navRef}>
      <div className="menuProduct__box">
        <ul className="menuProduct__list">
          {_.map(menu, (item, idx) => (
            <li key={item.id}>
              <AnchorLink to={`/product/${slug}#${item.id}`} title="Our team">
                {item.name}
                {t(`product.menu.${item.key}`, menuDefaultText[idx])}
              </AnchorLink>
            </li>
          ))}
        </ul>
        {scrolled
          && (buyNowLink ? (
            <div className="menuProduct__button double">
              <Button link={buyNowLink} text={t('product.header.buttonBuy')} />
              <Button
                outline
                link="/quotation/"
                text={t('product.header.buttonText')}
              />
            </div>
          ) : (
            <div className="menuProduct__button">
              <Button
                link="/quotation/"
                text={t('product.header.buttonText')}
              />
            </div>
          ))}
      </div>
    </div>
  )
}

export default Menu
